import { Injectable, signal } from '@angular/core';
import { HeaderConfig } from '@core/models';

@Injectable()
export class LayoutService {
  #headerConfig = signal<HeaderConfig>({
    useHeader: true,
    useBackButton: true,
    useLogoutButton: false,
  });

  readonly headerConfig$ = this.#headerConfig.asReadonly();

  updateHeaderConfig(config: HeaderConfig) {
    this.#headerConfig.set(config);
  }
}
